var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation",attrs:{"aria-label":"Main Naviogation"}},[_c('router-link',{staticClass:"head-link",attrs:{"to":"/","title":"Go to Fatty home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[
        isActive && 'router-link-active',
        isExactActive && 'router-link-exact-active'
      ],attrs:{"title":"See my progress in a chart.","href":href},on:{"click":function($event){return _vm.hideMenu(navigate)}}},[_c('figure',{staticStyle:{"--aspect-ratio":"1/1"}},[_c('logo')],1),_c('span',[_vm._v("FATTY BY TIM BENNIKS")])])]}}])}),_c('button',{staticClass:"hamburger hamburger--3dx",class:{ 'is-active': _vm.burgerActive },attrs:{"id":"button","aria-expanded":_vm.burgerActive,"type":"button","aria-haspopup":"true","aria-controls":"menu","aria-label":"Navigation"},on:{"click":function($event){return _vm.toggleMenu(false)}}},[_vm._m(0)]),_c('ul',{staticClass:"dropdown-menu",attrs:{"id":"menu","tabindex":"-1"}},[_c('router-link',{attrs:{"to":"/progress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var route = ref.route;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',[_c('a',{class:[
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active'
          ],attrs:{"title":"See my progress in a chart.","href":href},on:{"click":function($event){return _vm.toggleMenu(navigate)}}},[_vm._v("Progress.")])])]}}])}),_c('router-link',{attrs:{"to":"/about"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var route = ref.route;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',[_c('a',{class:[
            isActive && 'router-link-active',
            isExactActive && 'router-link-exact-active'
          ],attrs:{"title":"Read about Fatty.","href":href},on:{"click":function($event){return _vm.toggleMenu(navigate)}}},[_vm._v(" About. ")])])]}}])}),_vm._m(1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"https://timbenniks.nl/","title":"Go to timbenniks.nl","rel":"noopener","target":"_blank"}},[_vm._v(" timbenniks.com. ")])])}]

export { render, staticRenderFns }