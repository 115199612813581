<template>
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
      <g fill="#c50d0d">
        <path
          d="M26.101 54L27 57.923c.661.048 1.327.077 2 .077s1.339-.029 2-.077L32 54h-5.899zM9.423 39.755c-3.71-6.428-5.711-16.385-5.381-25.473A28.805 28.805 0 000 29c0 8.754 3.885 16.596 10.018 21.914v-8.926c0-.786-.202-1.552-.595-2.233zM53.957 14.233c.339 9.089-1.657 19.054-5.363 25.493a4.414 4.414 0 00-.592 2.226v8.944C54.124 45.579 58 37.745 58 29a28.86 28.86 0 00-4.043-14.767z"
        />
      </g>
      <path
        d="M31.919 54H32l-1 3.924A28.803 28.803 0 0043.692 54a29.041 29.041 0 004.31-3.104v-6.821c-6.845.498-12.745 4.348-16.083 9.925z"
        fill="#fbce9d"
      />
      <path
        d="M53.957 14.233a29.133 29.133 0 00-2.043-2.997l-.104-.132a29.231 29.231 0 00-1.093-1.313l-.043-.05a29.126 29.126 0 00-2.529-2.514l-.182-.158a28.312 28.312 0 00-1.218-.998c-.053-.041-.104-.083-.157-.123a29.049 29.049 0 00-2.898-1.949c-.101-.06-.203-.117-.305-.176a29.351 29.351 0 00-1.26-.681c-.1-.051-.199-.104-.3-.154a28.747 28.747 0 00-3.18-1.34c-.142-.05-.285-.097-.428-.145-.418-.14-.841-.271-1.268-.392-.145-.041-.29-.085-.436-.124A28.763 28.763 0 0034.82.583l-.045-.008a28.44 28.44 0 00-1.664-.281C32.94.27 32.768.25 32.596.229a30.534 30.534 0 00-1.292-.131c-.174-.014-.348-.029-.523-.04A30.858 30.858 0 0029 0c-.599 0-1.193.024-1.783.06-.174.011-.346.026-.519.039-.435.034-.867.078-1.297.131-.171.021-.342.041-.512.065-.56.08-1.116.171-1.665.282l-.045.008c-.572.117-1.135.254-1.694.404-.145.039-.289.082-.433.123-.428.122-.852.253-1.271.393-.142.048-.285.094-.426.144a28.8 28.8 0 00-3.183 1.341c-.099.049-.196.101-.295.151-.428.218-.851.446-1.266.684l-.299.172a29.132 29.132 0 00-2.902 1.952l-.151.119c-.417.324-.826.658-1.224 1.004-.06.051-.12.102-.178.154a29.066 29.066 0 00-2.533 2.517l-.039.045c-.379.428-.744.869-1.098 1.319l-.1.127a29.032 29.032 0 00-2.045 3c-.339 9.101 1.664 19.082 5.38 25.522.393.681.596 1.447.595 2.233v2.088c6.846.497 12.745 4.347 16.083 9.924h5.818c3.338-5.577 9.238-9.427 16.083-9.924v-2.076-.048a4.414 4.414 0 01.592-2.226c3.707-6.44 5.703-16.405 5.364-25.494z"
        fill="#fbce9d"
      />
      <path
        d="M28.003 32c-9.043 0-13.493 1.104-20.765 3.023.643 1.727 1.372 3.323 2.185 4.732.393.681.596 1.447.595 2.233v2.088c6.846.497 12.745 4.347 16.083 9.924h5.818c3.338-5.577 9.238-9.427 16.083-9.924V42v-.048c-.003-.784.2-1.547.592-2.226.879-1.527 1.66-3.274 2.339-5.169C44.09 32.927 36.289 32 28.003 32z"
        fill="#fff"
      />
      <circle cx="29" cy="21" r="2" fill="#f7b563" />
      <circle cx="29" cy="26" r="1" fill="#f7b563" />
      <circle cx="29" cy="15" r="1" fill="#f7b563" />
      <circle cx="25" cy="26" r="1" fill="#f7b563" />
      <circle cx="27" cy="28" r="1" fill="#f7b563" />
      <circle cx="33" cy="26" r="1" fill="#f7b563" />
      <circle cx="31" cy="28" r="1" fill="#f7b563" />
      <path
        d="M26.101 54c-3.338-5.577-9.238-9.427-16.083-9.924v6.838A29.02 29.02 0 0014.308 54 28.824 28.824 0 0027 57.924L26.101 54z"
        fill="#fbce9d"
      />
      <g fill="#c7cac7">
        <path
          d="M45.45 36.105a1 1 0 00-.895 1.789L46.767 39l-2.211 1.105a1 1 0 00.894 1.79l2.804-1.402c.091-.263.199-.522.34-.766.296-.514.577-1.062.85-1.624l-3.994-1.998zM13.912 36.553a1.002 1.002 0 00-1.342-.447l-4 2-.01.008c.277.568.562 1.122.862 1.642.135.233.238.479.328.729l2.82 1.41a1 1 0 00.896-1.79L11.254 39l2.211-1.105a1 1 0 00.447-1.342z"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Logo"
};
</script>
<style lang="scss">
.logo {
  line-height: 1;
}
</style>
